svg {
  vertical-align: middle;
}
body {
  margin: 0;
}
.jasstafel {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width:100vw;
  max-width:440px;
  position: relative;
  align-content: center;
  margin: auto;
  color: white;
  margin-bottom: 0;
  display: block;
  box-sizing: border-box;
}

.jasstafelline{
  text-align: center;
  position: absolute;
}
.jasstafel_z {
  background-image: url("Jasstafel_z.jpg");
  height:132.045vw;
  max-height:581px;
  font-family: sans-serif;
  font-size: 1.8em;
}
.jasstafel_blank {
  background-image: url("Jasstafel_leer.jpg");
  height:132.841vw;
  max-height:581px;
  font-family: 'Caveat', cursive;
}
.tafelcontent {
  padding:17%;
}